import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "progress-bar"
    }}>{`Progress Bar`}</h1>
    <p>{`To change color just change `}<inlineCode parentName="p">{`background-`}</inlineCode>{` class to color that you need.`}</p>
    <p>To see all the colors and their variations go to the <a className='pink' href='/styleguide'>Styleguide page</a></p>
    <div className="progress mb-3">
  <div className="progress-bar" role="progressbar" style={{
        "width": "25%"
      }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div className="progress mb-3">
  <div className="progress-bar background-lilac" role="progressbar" style={{
        "width": "75%"
      }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="progress">
  <div
    class="progress-bar"
    role="progressbar"
    style="width: 25%"
    aria-valuenow="25"
    aria-valuemin="0"
    aria-valuemax="100"
  ></div>
</div>
<div class="progress">
  <div
    class="progress-bar background-lilac"
    role="progressbar"
    style="width: 75%"
    aria-valuenow="75"
    aria-valuemin="0"
    aria-valuemax="100"
  ></div>
</div>
`}</code></pre>
    <h2 {...{
      "id": "dashed"
    }}>{`Dashed`}</h2>
    <p>{`To dashe the bar use the class `}<inlineCode parentName="p">{`.background-dashed`}</inlineCode></p>
    <div className="progress mb-3">
  <div className="progress-bar background-dashed" role="progressbar" style={{
        "width": "50%"
      }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="progress">
  <div
    class="progress-bar background-dashed"
    role="progressbar"
    style="width: 50%"
    aria-valuenow="50"
    aria-valuemin="0"
    aria-valuemax="100"
  ></div>
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      